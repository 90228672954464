require('./bootstrap');
/**
 * init calendar widget
 * @type Calendar
 */
/*
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import nbLocale from '@fullcalendar/core/locales/nb';
import listPlugin from '@fullcalendar/list';

document.addEventListener("DOMContentLoaded", function() {
    // let calendarEl = document.getElementById("calendar");
    let calendarEl = document.getElementsByClassName("calender-widget");
    let board_id = document.getElementById("board_id").value;
    const site_lang = $("#site_lang").val() === 'en' ? 'en' : nbLocale;






    if (calendarEl.length) {
        // console.log(calendarEl)
        for (let i = 0; i < calendarEl.length; i++) {
            let views = ""
            let init_view = ""
            if (i === 0) {
                views = "timeGridWeek,listWeek"
                init_view = "listWeek"
            } else {
                views = "dayGridMonth"
                init_view = "dayGridMonth"
            }
            let calendar = new Calendar(calendarEl[i], {
                plugins: [dayGridPlugin, timeGridPlugin, listPlugin ],
                editable: false,
                events: `/api/eventsbyboard/${board_id}`,
                initialView: init_view,
                dayMaxEventRows: 4,
                locale: site_lang,
                // contentHeight: 2900,
                headerToolbar: {
                    left: "prev,next today",
                    center: "title",
                    right: views
                }
            });
            calendar.render();
        }
    }
});*/
